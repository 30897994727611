import { Labels, Titles, CollapseInfo } from './types';
import { RECOGNITION_LEVELS } from '@typings/recognition';
import { TFunction } from 'i18next';

export const defaultCollapseInfo: CollapseInfo = {
  [RECOGNITION_LEVELS.PLATINUM]: true,
  [RECOGNITION_LEVELS.GOLD]: true,
  [RECOGNITION_LEVELS.SILVER]: true,
  [RECOGNITION_LEVELS.BRONZE]: true,
};

export const getTitles = (t: TFunction): Titles => ({
  [RECOGNITION_LEVELS.PLATINUM]: t(
    'recognition.level.platinum',
    'PLATINUM level'
  ),
  [RECOGNITION_LEVELS.GOLD]: t('recognition.level.gold', 'GOLD level'),
  [RECOGNITION_LEVELS.SILVER]: t('recognition.level.silver', 'SILVER level'),
  [RECOGNITION_LEVELS.BRONZE]: t('recognition.level.bronze', 'BRONZE level'),
});

export const getLabels = (t: TFunction): Labels => ({
  [RECOGNITION_LEVELS.PLATINUM]: t(
    'recognition.sponsored.platinum',
    'Sponsored 75 or more members'
  ),
  [RECOGNITION_LEVELS.GOLD]: t(
    'recognition.sponsored.gold',
    'Sponsored 50 - 74 members'
  ),
  [RECOGNITION_LEVELS.SILVER]: t(
    'recognition.sponsored.silver',
    'Sponsored 30 - 49 members'
  ),
  [RECOGNITION_LEVELS.BRONZE]: t(
    'recognition.sponsored.bronze',
    'Sponsored 25 - 29 members'
  ),
});
