import React from 'react';

// Components
import IndividualListColumn from '@components/IndividualListColumn';
import ClubsList from '@components/ClubsList';

// Types
import { Club } from '@typings/graphql';
import { IndividualItemProps } from '@domain/membership-society/types';

// Hooks
import { useTranslation } from '@external/react-i18next';
import { EXTRA_MESSAGE_POSITION } from '@components/IndividualListColumn/IndividualListColumn';
import { getMembershipInfo } from '@use-cases/membership-society';

const DesktopIndividualItem = ({
  id,
  photoUri,
  localizedName,
  fullName,
  sponsorships,
  club,
  membership,
}: IndividualItemProps) => {
  const { t } = useTranslation();

  const membershipInfo = getMembershipInfo(membership, t);

  return (
    <div className="flex-wrap items-center flex-1 hidden desktop:flex">
      <IndividualListColumn
        id={id}
        photoUri={photoUri}
        localizedName={localizedName}
        fullName={fullName}
        associatedClubsInfo={[]}
        usingFlex
        isLinkToProfile={membershipInfo.showLink}
        extraMessage={
          <p className="text-xs mb-0 text-gray-400">
            {membershipInfo.translatedMembershipType}
          </p>
        }
        extraMessagePosition={EXTRA_MESSAGE_POSITION.BOTTOM}
      />
      <div className="flex-1 pl-24 pr-4 text-xs leading-6">
        <ClubsList clubs={[club as Club]} withLinkToClub />
      </div>
      <div className="flex-1 pl-24 pr-4 text-xs leading-6 text-right">
        {t('recognition.sponsorships-info', '{{sponsorships}} members', {
          sponsorships,
        })}
      </div>
    </div>
  );
};

export default DesktopIndividualItem;
