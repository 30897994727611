import { gql } from '@apollo/client';

export const fetchRecognitionDataQuery = gql`
  fragment RecognitionDataItem on RecognitionItemInfo {
    riDistrictId
    districtId
    individuals {
      individualId
      prefix
      firstName
      middleName
      lastName
      suffix
      localizedName
      photoUri
      sponsorships
      club {
        clubId
        clubName
        clubType
        physicalLocation {
          country
          state
        }
      }
      membership {
        type
        status
      }
    }
  }

  fragment RecognitionDataItems on RecognitionDataInfo {
    platinum {
      ...RecognitionDataItem
    }
    gold {
      ...RecognitionDataItem
    }
    silver {
      ...RecognitionDataItem
    }
    bronze {
      ...RecognitionDataItem
    }
  }

  query getRecognitionInfo($individualId: String!) {
    results: getRecognitionInfo(individualId: $individualId) {
      ...RecognitionDataItems
    }
  }
`;
