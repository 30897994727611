import { DIS } from './dis';
import {
  AddressType,
  ClubType,
  EmailType,
  Gender,
  PhoneType,
  FaxType,
  MembershipType,
  WeekdayFull,
  MeetingType,
  AccessLevel,
  Reason,
  ClubStatus,
} from './graphql';

const invert = <T extends string, S extends string>(
  input: Record<T, S>
): Record<S, T> => {
  const ret: Record<string, any> = {};
  Object.entries(input).forEach(([key, value]) => (ret[value as string] = key));
  return ret;
};

export enum From {
  DIS = 'DIS',
  GraphQL = 'GraphQL',
}

export enum Enum {
  EmailType = 'EmailType',
  Gender = 'Gender',
  AddressType = 'AddressType',
  PhoneType = 'PhoneType',
  ClubType = 'ClubType',
  FaxType = 'FaxType',
  MembershipType = 'MembershipType',
  MeetingType = 'MeetingType',
  Weekday = 'Weekday',
  AccessLevel = 'AccessLevel',
  Reason = 'Reason',
  ClubStatus = 'ClubStatus',
}

type TypesDIS = {
  [Enum.EmailType]: DIS.EmailType;
  [Enum.Gender]: DIS.Gender;
  [Enum.AddressType]: DIS.AddressType;
  [Enum.PhoneType]: DIS.PhoneType;
  [Enum.ClubType]: DIS.ClubType;
  [Enum.FaxType]: DIS.FaxType;
  [Enum.MembershipType]: DIS.MembershipType;
  [Enum.Weekday]: DIS.Weekday;
  [Enum.MeetingType]: DIS.MeetingType;
  [Enum.AccessLevel]: DIS.AccessLevel;
  [Enum.Reason]: DIS.Reason;
  [Enum.ClubStatus]: DIS.ClubStatus;
};

type TypesGraphQL = {
  [Enum.EmailType]: EmailType;
  [Enum.Gender]: Gender;
  [Enum.AddressType]: AddressType;
  [Enum.PhoneType]: PhoneType;
  [Enum.ClubType]: ClubType;
  [Enum.FaxType]: FaxType;
  [Enum.MembershipType]: MembershipType;
  [Enum.Weekday]: WeekdayFull;
  [Enum.MeetingType]: MeetingType;
  [Enum.AccessLevel]: AccessLevel;
  [Enum.Reason]: Reason;
  [Enum.ClubStatus]: ClubStatus;
};

type Input = {
  [From.DIS]: TypesDIS;
  [From.GraphQL]: TypesGraphQL;
};

type Return = {
  [From.DIS]: TypesGraphQL;
  [From.GraphQL]: TypesDIS;
};

const genderSpecialCases: { [key in DIS.Gender]?: Gender } = {
  'Not Given': Gender.NotGiven,
};

const clubTypeSpecialCases: { [key in DIS.ClubType]: ClubType } = {
  Rotary_Club: ClubType.RotaryClub,
  Rotaract_Club: ClubType.RotaractClub,
  Satellite_Club: ClubType.SatelliteClub,
  Rotaract_Satellite_Club: ClubType.RotaractSatelliteClub,
};

const accessLevelSpecialCases: { [key in DIS.AccessLevel]?: AccessLevel } = {
  'No Permission': AccessLevel.NoPermission,
  'Limited Viewer': AccessLevel.LimitedViewer,
};

const reasonSpecialCases: { [key in DIS.Reason]?: Reason } = {
  'Terminated - Non-payment of club dues': Reason.NonPaymentOfClubDues,
  'Terminated - Personal': Reason.Personal,
  'Terminated - Relocation': Reason.Relocation,
  'Terminated - Family Obligations': Reason.FamilyObligations,
  'Terminated - Business Obligations': Reason.BusinessObligations,
  'Terminated - Joining New Club': Reason.JoiningNewClub,
  'Terminated - By club for cause': Reason.ByClubForCause,
  'Terminated - Deceased': Reason.Deceased,
  'Terminated - Non-Attendance': Reason.NonAttendance,
  'Terminated - Health': Reason.Health,
  'Terminated - Lack of participation': Reason.LackOfParticipation,
  'Terminated - Joining Rotary Club': Reason.JoiningRotaryClub,
  'Terminated - No Contact Update': Reason.NoContactUpdate,
  'Terminated - Age': Reason.Age,
  'Terminated - School Graduation': Reason.SchoolGraduation,
};

// Warning: These are used in both directions of the conversion!
const specialCases: { [key in Enum]?: any } = {
  [Enum.Gender]: genderSpecialCases,
  [Enum.ClubType]: clubTypeSpecialCases,
  [Enum.AccessLevel]: accessLevelSpecialCases,
  [Enum.Reason]: reasonSpecialCases,
};

export const mapEnum = <T extends From, S extends Enum>(
  from: T,
  kind: S,
  value: Input[T][S]
): Return[T][S] => {
  // This is a very special case. Some addresses in DIS are marked as Unknown,
  // but DIS does not take this value as valid. In this case we just set the
  // address type to Home.
  if (from === From.DIS && kind === Enum.AddressType && value === 'Unknown') {
    return 'Home' as Return[T][S];
  }

  const specialCase =
    from === From.DIS
      ? specialCases[kind]?.[value]
      : specialCases[kind]
      ? invert(specialCases[kind])?.[value]
      : undefined;
  return (specialCase || value) as Return[T][S];
};
