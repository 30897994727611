import { RecognitionMembership } from '@typings/graphql';
import { TFunction } from 'i18next';

export const getMembershipInfo = (
  membership: RecognitionMembership,
  t: TFunction
) => {
  const isActive = membership.status === 'Active';
  if (!isActive) {
    return {
      translatedMembershipType: t('recognition.former-member', 'Former Member'),
      showLink: isActive,
    };
  }
  let membershipType = '';
  if (membership.type === 'Member') {
    membershipType = t('recognition.member', 'Member');
  }
  if (membership.type === 'Honorary Member') {
    membershipType = t('recognition.honorary-member', 'Honorary Member');
  }

  return {
    translatedMembershipType: membershipType,
    showLink: isActive,
  };
};
