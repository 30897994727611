import React from 'react';

import { RouteComponentProps, Router } from '@reach/router';

import AuthenticatedRoute from '@components/Auth/AuthenticatedRoute';
import RequireLogin from '@components/Auth/RequireLogin';
import { OneColumn } from '@components/Layouts/OneColumn';
import Recognition from '@presenters/web/pages/MembershipSociety/Recognition';

import { Helmet } from '@external/react-helmet-async';
import { useTranslation } from '@external/react-i18next';

const MembershipSociety: React.FC<RouteComponentProps & {
  pageContext: { languagePrefix: string };
}> = ({ pageContext }) => {
  const { t } = useTranslation();

  return (
    <RequireLogin>
      <Helmet
        titleTemplate={`${t(
          'metadata.title.membership-society',
          'Membership Society'
        )}: %s | ${t('metadata.title.default', 'My Rotary')}`}
      />
      <OneColumn className="desktop:px-32 px-0">
        <Router basepath={`/${pageContext.languagePrefix}membership-society`}>
          <AuthenticatedRoute
            path="/recognition"
            Component={() => <Recognition />}
          />
        </Router>
      </OneColumn>
    </RequireLogin>
  );
};

export default MembershipSociety;
