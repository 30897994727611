// Libs
import React from 'react';
import classNames from 'classnames';

// Components
import ClubsList from '@components/ClubsList';
import { Avatar } from '@components/Avatar';
import Link from '@components/Link';

// Types
import { Club } from '@typings/graphql';
import { IndividualItemProps } from '@domain/membership-society/types';

// Hooks
import { useTranslation } from '@external/react-i18next';

// Utils
import { getMembershipInfo } from '@use-cases/membership-society';

const MobileIndividualItem = ({
  id,
  photoUri,
  localizedName,
  fullName,
  sponsorships,
  club,
  membership,
}: IndividualItemProps) => {
  const { t } = useTranslation();

  const membershipInfo = getMembershipInfo(membership, t);

  return (
    <div className="flex-wrap items-center flex-1 desktop:hidden flex px-4">
      <Avatar alt={fullName} photoUri={photoUri} />
      <div className="w-3/4">
        <h3
          className={classNames('text-md font-bold leading-6 mb-0', {
            underline: membershipInfo.showLink,
          })}
        >
          {membershipInfo.showLink ? (
            <Link noLinkStyles to={`/profile/${id}`}>
              {fullName}
            </Link>
          ) : (
            fullName
          )}
        </h3>
        {localizedName && (
          <p className="text-xs mb-0 text-gray-400">{localizedName}</p>
        )}
        <p className="text-xs mb-0 text-gray-400">
          {membershipInfo.translatedMembershipType}
        </p>
        <ClubsList clubs={[club as Club]} withLinkToClub />
        {t('recognition.sponsorships-info', '{{sponsorships}} members', {
          sponsorships,
        })}
      </div>
    </div>
  );
};

export default MobileIndividualItem;
