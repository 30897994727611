// Libs
import React, { PropsWithChildren, useState } from 'react';
import Collapse from '@kunukn/react-collapse';

// Components
import Icon from '@components/Icon';
import { ResultsListItem } from '@components/ResultsList';

type CollapseHeaderProps = {
  title: string;
  count: number;
  rightLabel: string;
  openByDefault: boolean;
};
const CollapseHeader = ({
  title,
  count,
  rightLabel,
  openByDefault,
  children,
}: PropsWithChildren<CollapseHeaderProps>) => {
  const [isOpen, setIsOpen] = useState(openByDefault);
  return (
    <>
      <ResultsListItem
        className="mt-10 flex-wrap items-center h-16 bg-gray-100 desktop:border-t-3 border-gray-300"
        borderBottomClass="border-b-3"
        spacingY={2}
      >
        <span
          className="cursor-pointer"
          onClick={() => setIsOpen(!isOpen)}
          data-testid="toggle-expand-btn"
        >
          <Icon
            className="inline-block mx-4"
            name={`arrows/${isOpen ? 'up' : 'down'}`}
            size="11"
            color="gray-600"
          />
        </span>
        <div className="desktop:flex desktop:flex-1 desktop:items-center">
          <p className="flex-1 text-left desktop:text-lg text-md leading-16 mb-0 font-bold uppercase desktop:font-normal desktop:transform-none">
            <span
              className="cursor-pointer"
              onClick={() => setIsOpen(!isOpen)}
              dangerouslySetInnerHTML={{ __html: `${title} (${count})` }}
            />
          </p>
          <p
            className="flex-1 text-right leading-16 pr-4 text-xs mb-0"
            dangerouslySetInnerHTML={{ __html: `${rightLabel}` }}
          />
        </div>
      </ResultsListItem>
      <Collapse isOpen={isOpen} className="collapse-css-transition mb-4">
        {children}
      </Collapse>
    </>
  );
};

export default CollapseHeader;
