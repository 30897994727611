import {
  GetRecognitionInfoQuery,
  GetRecognitionInfoQueryVariables,
} from '@typings/operations';
import { useLazyQuery } from '@apollo/client';
import { fetchRecognitionDataQuery } from '../queries';

export const useFetchRecognitionData = () =>
  useLazyQuery<GetRecognitionInfoQuery, GetRecognitionInfoQueryVariables>(
    fetchRecognitionDataQuery
  );
