import * as React from 'react';

import classNames from 'classnames';

import Link from '@components/Link';

import { getClubDetailsPath } from '@use-cases/clubs';

import { getClubTypeName } from '@utils/getClubTypeName';
import { getPhysicalLocation } from '@utils/getPhysicalLocation';

import { useTranslation } from '@external/react-i18next';

import { DIS } from '@typings/dis';
import { Enum, From, mapEnum } from '@typings/map-enum';
import { Club } from '@typings/resolvers';

interface Props {
  clubs?: Partial<Club>[];
  classes?: string;
  isExpanded?: boolean;
  withLinkToClub?: boolean;
}

const ClubsList: React.FC<Props> = ({
  clubs,
  classes = '',
  isExpanded,
  withLinkToClub = false,
}) => {
  const { t } = useTranslation();
  const renderClub = (
    { clubId, clubName, clubType, physicalLocation }: Partial<Club>,
    index: number
  ): JSX.Element => (
    <div className="last:mb-0 mb-3" key={`${index}-${clubId}`}>
      <p
        className={classNames('text-xs mb-0', {
          'truncate desktop:whitespace-normal desktop:text-clip desktop:overflow-visible': !isExpanded,
        })}
      >
        {withLinkToClub ? (
          <Link
            to={getClubDetailsPath(clubId)}
            key={clubId}
            noLinkStyles
            className="text-gray-600 underline"
          >
            {clubName}
          </Link>
        ) : (
          clubName
        )}
        {clubType &&
          ` (${getClubTypeName(
            t,
            mapEnum(From.DIS, Enum.ClubType, clubType as DIS.ClubType)
          )})`}
      </p>
      <p
        className={classNames('text-xs mb-0', {
          'truncate desktop:whitespace-normal desktop:text-clip desktop:overflow-visible': !isExpanded,
        })}
      >
        {getPhysicalLocation(physicalLocation)}
      </p>
    </div>
  );

  return clubs?.length ? (
    <div className={classes}>{clubs.map(renderClub)}</div>
  ) : null;
};

export default ClubsList;
