import React, { ReactElement } from 'react';

import classNames from 'classnames';

import { Avatar } from '@components/Avatar';
import Link from '@components/Link';

import { AssigneeActiveMembership, ClubsInfo } from './ClubsInfo';

import { storePreviousPagePath } from '@utils/storePreviousPagePath';

import { Maybe } from '@typings/graphql';
import { Club } from '@typings/operations';

export enum EXTRA_MESSAGE_POSITION {
  TOP = 'top',
  BOTTOM = 'bottom',
}

interface Props {
  id: string;
  photoUri?: string;
  localizedName?: string | null;
  fullName?: string;
  associatedClubsInfo?: Maybe<Partial<Club>[]>;
  activeMemberships?: AssigneeActiveMembership[];
  isLinkToProfile?: boolean;
  usingFlex?: boolean;
  roleTitle?: ReactElement | string;
  children?: React.ReactNode;
  terms?: ReactElement | string | boolean;
  extraMessage?: ReactElement | boolean | null;
  extraMessagePosition?: EXTRA_MESSAGE_POSITION;
  showAvatar?: boolean;
  wrapperClasses?: string;
  hasMemberships?: { honorary: boolean; notHonorary: boolean };
  isMobileExpanded?: boolean;
  isSelectMemberTab?: boolean;
  profilePreviousLinkPath?: string;
}

const IndividualListColumn: React.FC<Props> = ({
  id,
  photoUri,
  localizedName,
  fullName,
  associatedClubsInfo,
  activeMemberships,
  isLinkToProfile = true,
  usingFlex = false,
  roleTitle,
  children,
  terms,
  extraMessage,
  extraMessagePosition = EXTRA_MESSAGE_POSITION.TOP,
  showAvatar = true,
  wrapperClasses,
  hasMemberships,
  isMobileExpanded,
  isSelectMemberTab,
  profilePreviousLinkPath,
}) => {
  const IndividualInfo = (
    <>
      {roleTitle}
      {extraMessagePosition === EXTRA_MESSAGE_POSITION.TOP && extraMessage}
      <h3
        className={classNames('text-md font-bold leading-6 mb-0', {
          underline: isLinkToProfile,
        })}
      >
        {isLinkToProfile ? (
          <Link
            noLinkStyles
            to={`/profile/${id}`}
            onClick={() => storePreviousPagePath(profilePreviousLinkPath)}
          >
            {fullName}
          </Link>
        ) : (
          fullName
        )}
      </h3>

      {localizedName && (
        <p className="text-xs mb-0 text-gray-400">{localizedName}</p>
      )}
      {terms}
      {extraMessagePosition === EXTRA_MESSAGE_POSITION.BOTTOM && extraMessage}
    </>
  );

  const reformattedBlock = usingFlex ? (
    <div className="flex-1">{IndividualInfo}</div>
  ) : (
    IndividualInfo
  );

  return (
    <div
      className={classNames(
        'flex-1 text-xs leading-6',
        {
          'min-h-16 pl-16 desktop:pl-24 pr-4': showAvatar,
          flex: usingFlex,
          'items-center': usingFlex,
        },
        wrapperClasses
      )}
    >
      {showAvatar && (
        <Avatar
          alt={fullName || ''}
          photoUri={photoUri}
          className={classNames('-ml-16 desktop:-ml-24', {
            'float-left': !usingFlex,
          })}
        />
      )}
      {reformattedBlock}
      {associatedClubsInfo && (
        <ClubsInfo
          associatedClubsInfo={associatedClubsInfo}
          activeMemberships={activeMemberships}
          hasMemberships={hasMemberships}
          isMobileExpanded={isMobileExpanded}
          isSelectMemberTab={isSelectMemberTab}
        />
      )}
      {children}
    </div>
  );
};

export default IndividualListColumn;
