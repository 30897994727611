import React, { useEffect } from 'react';

import Icon from '@components/Icon';
import Loading from '@components/Loading';
import Title from '@components/Title';

import CollapseHeader from './CollapseHeader';
import DistrictCollapseItem from './DistrictCollapseItem';

import {
  defaultCollapseInfo,
  getLabels,
  getTitles,
} from '@domain/membership-society/constants';
import {
  RecognitionDataItem,
  RecognitionDataType,
} from '@domain/membership-society/types';

import { useFetchRecognitionData } from '@repositories/membership-society/hooks';

import { useTranslation } from '@external/react-i18next';
import { useAppConfig } from '@hooks/appConfig';

import { RECOGNITION_LEVELS } from '@typings/recognition';

const Recognition = () => {
  const { t } = useTranslation();

  const { user } = useAppConfig();

  const [
    fetchRecognitionData,
    { data: recognitionData, loading: recognitionLoading },
  ] = useFetchRecognitionData();

  useEffect(() => {
    if (user?.individualId) {
      fetchRecognitionData({
        variables: {
          individualId: user.individualId,
        },
      });
    }
  }, [user?.individualId]);

  if (recognitionLoading) {
    return <Loading />;
  }

  const getTotal = (el: RecognitionDataItem[]) =>
    el.reduce((accum, curr) => accum + curr.individuals.length, 0) || 0;

  const titles = getTitles(t);
  const labels = getLabels(t);

  const results = recognitionData?.results as RecognitionDataType;

  return (
    <>
      <Title className="desktop:px-0 px-4">
        {t('recognition.title', 'Membership Society')}
      </Title>
      <h2 className="gray-600 desktop:pb-5 desktop:px-0 px-4">
        {t('recognition.content.title', 'New Member Sponsors')}
      </h2>
      <div className="recognition-header">
        <Icon
          name="ui/recognition-header"
          className="recognition-header-logo"
        />
        <span className="recognition-header-text">
          {t(
            'recognition.content.description',
            'Rotary gratefully acknowledges these dedicated members who have demonstrated an extraordinary commitment in sharing the gift of Rotary. These people of action have expanded Rotary’s reach by sponsoring 25 or more new members.'
          )}
        </span>
      </div>
      <div className="rectangle-desktop" />
      <span className="recognition-header-text-mobile">
        {t(
          'recognition.content.description',
          'Rotary gratefully acknowledges these dedicated members who have demonstrated an extraordinary commitment in sharing the gift of Rotary. These people of action have expanded Rotary’s reach by sponsoring 25 or more new members.'
        )}
      </span>
      {recognitionData?.results && (
        <ul>
          {Object.values(RECOGNITION_LEVELS).map(level => (
            <CollapseHeader
              key={level}
              title={titles[level]}
              rightLabel={labels[level]}
              count={getTotal(results[level])}
              openByDefault={defaultCollapseInfo[level]}
            >
              <ul>
                {results[level].map(item => (
                  <DistrictCollapseItem
                    {...item}
                    key={item.riDistrictId}
                    shouldBeOpened={level === RECOGNITION_LEVELS.PLATINUM}
                  />
                ))}
              </ul>
            </CollapseHeader>
          ))}
        </ul>
      )}
    </>
  );
};

export default Recognition;
