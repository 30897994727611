import React, { useState } from 'react';

import classNames from 'classnames';

import ClubsList from '@components/ClubsList';
import { Icon } from '@components/Icon';

import { useTranslation } from '@external/react-i18next';
import { useMobileLayout } from '@hooks/useMobileLayout';

import { Maybe, MembershipType } from '@typings/graphql';
import { Club } from '@typings/operations';

export type AssigneeActiveMembership = {
  type?: Maybe<string> | undefined;
  admissionDate?: string | undefined;
  terminationDate?: Maybe<string> | undefined;
  clubId?: string;
};

interface Props {
  associatedClubsInfo?: Maybe<Partial<Club>[]>;
  activeMemberships?: AssigneeActiveMembership[];
  hasMemberships?: { honorary: boolean; notHonorary: boolean };
  isMobileExpanded?: boolean;
  isSelectMemberTab?: boolean;
}

export const ClubsInfo: React.FC<Props> = ({
  associatedClubsInfo,
  activeMemberships,
  hasMemberships,
  isMobileExpanded,
  isSelectMemberTab,
}) => {
  const { isMobileLayout } = useMobileLayout();
  const { t } = useTranslation();
  const isHonoraryOnly =
    hasMemberships?.honorary && !hasMemberships.notHonorary;
  const [isHonoraryDisplayed, setIsHonoraryDisplayed] = useState(
    isHonoraryOnly
  );
  const displayClubsWithHonoraryOnly =
    hasMemberships?.honorary &&
    (isMobileExpanded || (!isMobileLayout && isHonoraryDisplayed));

  const honoraryMembershiplabel = t(
    'membership.type.honorary-membership-label',
    'Honorary membership(s)'
  );

  let displayHonoraryMembershipsLabel;

  const getClubsByMembershipType = (membershipTypes: string[]) => {
    const allowedActiveMembership = activeMemberships?.filter(
      ({ type }) => type && membershipTypes.includes(type)
    );
    return associatedClubsInfo?.filter(({ clubId: associatedClubId }) =>
      allowedActiveMembership?.find(({ clubId }) => clubId === associatedClubId)
    );
  };

  if ((isMobileLayout && isMobileExpanded) || isHonoraryOnly) {
    displayHonoraryMembershipsLabel = (
      <div
        className={classNames({
          'pb-2': !isMobileLayout || isMobileExpanded,
          'pt-3': isMobileExpanded && !isHonoraryOnly,
        })}
      >
        {honoraryMembershiplabel}:
      </div>
    );
  }

  if (!isMobileLayout && !isHonoraryOnly) {
    displayHonoraryMembershipsLabel = (
      <div
        className={classNames('cursor-pointer flex items-center pt-2', {
          'pb-2': isHonoraryDisplayed,
        })}
        onClick={() => setIsHonoraryDisplayed(!isHonoraryDisplayed)}
      >
        <Icon
          name={
            isHonoraryDisplayed
              ? 'squares/icon-collapse'
              : 'squares/icon-expand'
          }
          size="17"
          color="dark-blue-400"
        />
        <span className="ml-1 text-dark-blue-400">
          {honoraryMembershiplabel}
        </span>
      </div>
    );
  }

  return (
    <>
      {(hasMemberships?.notHonorary || isSelectMemberTab) && (
        <ClubsList
          clubs={getClubsByMembershipType([
            MembershipType.Member,
            MembershipType.Rotaractor,
          ])}
        />
      )}
      {hasMemberships?.honorary && displayHonoraryMembershipsLabel}
      {displayClubsWithHonoraryOnly && (
        <ClubsList
          clubs={getClubsByMembershipType([
            MembershipType.Honorary,
            MembershipType.RotaractHonorary,
          ])}
        />
      )}
    </>
  );
};
