// Libs
import React, { useState } from 'react';
import Collapse from '@kunukn/react-collapse';

// Components
import DesktopIndividualItem from './DesktopIndividualItem';
import MobileIndividualItem from './MobileIndividualItem';
import { ResultsListItem } from '@components/ResultsList';
import Link from '@components/Link';
import Icon from '@components/Icon';

// Utils
import { constructFullName } from '@utils/constructFullName';

// Types
import { RecognitionDataItem } from '@domain/membership-society/types';

// Hooks
import { useTranslation } from '@external/react-i18next';

interface Props extends RecognitionDataItem {
  shouldBeOpened: boolean;
}

const DistrictCollapseItem = ({
  districtId,
  riDistrictId,
  individuals,
  shouldBeOpened,
}: Props) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(shouldBeOpened);
  return (
    <>
      <ResultsListItem className="flex-wrap items-center" spacingY={2}>
        <span className="flex-1 text-left leading-16 text-xs uppercase font-bold">
          <span className="cursor-pointer" onClick={() => setIsOpen(!isOpen)}>
            <Icon
              className="inline-block mx-4"
              name={`arrows/${isOpen ? 'up' : 'down'}`}
              size="11"
              color="gray-600"
            />
            {t('tab.district-district-title', 'District {{riDistrictId}}', {
              riDistrictId,
            })}{' '}
            ({individuals.length})
          </span>
        </span>
        <span className="text-right leading-16 pr-4">
          <Link
            to={`/district/${districtId}/details`}
            className="text-dark-blue-400 hover:text-bright-blue-600 text-xs"
            noLinkStyles
          >
            {t('recognition.view-district', 'View District')}
          </Link>
        </span>
      </ResultsListItem>
      <Collapse isOpen={isOpen} className="collapse-css-transition">
        <ul>
          {individuals.map(
            (
              {
                individualId: id,
                prefix,
                firstName,
                middleName,
                lastName,
                suffix,
                localizedName,
                photoUri,
                sponsorships,
                club,
                membership,
              },
              index
            ) => {
              const props = {
                id,
                photoUri: photoUri || '',
                sponsorships,
                localizedName: localizedName || undefined,
                fullName: constructFullName({
                  prefix,
                  firstName,
                  middleName,
                  lastName,
                  suffix,
                }),
                club,
                membership,
              };
              return (
                <ResultsListItem key={index}>
                  <DesktopIndividualItem {...props} />
                  <MobileIndividualItem {...props} />
                </ResultsListItem>
              );
            }
          )}
        </ul>
      </Collapse>
    </>
  );
};

export default DistrictCollapseItem;
